<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      class="alert-modal"
      content-class="modal-register"
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="text-center flex-grow-1 text-r-20">แก้ไขข้อมูลลูกค้า</div>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="d-block">
        <div v-if="isAddOptionalTelephone" class="register-box p-3">
          <b-row no-gutters>
            <b-col md="8">
              <InputText
                class="pr-3"
                type="number"
                textFloat="เบอร์โทรศัพท์สำรอง"
                v-model="telList[0].tel"
                placeholder="เบอร์โทรศัพท์สำรอง"
                :isValidate="$v.telList.$each[0].tel.$error"
                :v="$v.telList.$each[0].tel"
              >
              </InputText>
            </b-col>
            <b-col md="3">
              <InputText
                type="number"
                textFloat=" "
                placeholder="เบอร์ต่อ"
                v-model="telList[0].ext"
                :disabled="!telList[0].tel"
              ></InputText>
            </b-col>
            <b-col
              md="1"
              class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
            >
              <font-awesome-icon
                icon="trash-alt"
                title="delete-btn"
                size="lg"
                class="pointer text-primary icon-delete"
                @click.stop="deleteTel(0)"
              />
            </b-col>
            <template v-for="(item, index) in telList" v-if="index != 0">
              <b-col md="8" :key="field + '1' + index">
                <InputText
                  class="pr-3"
                  type="number"
                  textFloat=" "
                  v-model="item.tel"
                  placeholder="เบอร์โทรศัพท์สำรอง"
                  :isValidate="$v.telList.$each[index].tel.$error"
                  :v="$v.telList.$each[index].tel"
                >
                </InputText>
              </b-col>
              <b-col md="3" :key="field + '2' + index">
                <InputText
                  type="number"
                  textFloat=" "
                  placeholder="เบอร์ต่อ"
                  v-model="item.ext"
                  :disabled="!item.tel"
                ></InputText>
              </b-col>
              <b-col
                :key="field + '3' + index"
                md="1"
                class="w-100 text-center d-flex justify-content-center align-items-center mt-4"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  title="delete-btn"
                  size="lg"
                  class="pointer text-primary icon-delete"
                  @click.stop="deleteTel(index)"
                />
              </b-col>
            </template>
          </b-row>

          <div class="d-flex justify-content-center">
            <b-button
              variant="primary-color"
              class="btn-modal btn-hover w-100 mt-3"
              @click="addOptinalTel"
              :disabled="
                isLoading ||
                (telList.length == limitOptionalTel && limitOptionalTel !== '')
              "
            >
              เพิ่มเบอร์โทรศัพท์สำรอง ( {{ telList.length }} /
              {{ limitOptionalTel }} )
            </b-button>
          </div>
        </div>
        <RegisterFields
          v-else
          @AddOptionalTelephone="AddOptionalTelephone"
          ref="registerFields"
          :form="form"
          :isEdit="true"
          @success="handleSuccess()"
          :fields="fields"
          :dynamicField="dynamicField"
        />
      </div>
      <template #modal-footer="{ hide }">
        <div
          class="d-flex justify-content-between w-100 my-0"
          v-if="isAddOptionalTelephone"
        >
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="closeOptionalPhone"
            :disabled="isLoading"
          >
            ย้อนกลับ
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="saveOptionalPhone"
            :disabled="isLoading"
          >
            บันทึก
          </b-button>
        </div>
        <div class="d-flex justify-content-between w-100 my-0" v-else>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1 ml-1"
            @click="save"
            :disabled="isLoading"
          >
            แก้ไข
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RegisterFields from "../../../../components/field/RegisterFields.vue";
import InputText from "@/components/inputs/InputText";
import { minLength, maxLength } from "vuelidate/lib/validators";

export default {
  components: {
    RegisterFields,
    InputText,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {},
      now: null,
      genderList: [
        { text: "ชาย", value: "Male" },
        { text: "หญิง", value: "Female" },
        { text: "ไม่ระบุเพศ", value: "N/A" },
      ],
      fields: [],
      dynamicField: [],
      isAddOptionalTelephone: false,
      telList: [],
    };
  },

  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  validations() {
    return {
      telList: {
        $each: {
          tel: {
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
      limitOptionalTel: "getLimitTelephone",
    }),
  },

  watch: {
    telList: {
      handler(newVal) {
        newVal.forEach((item) => {
          if (!item.tel) {
            item.ext = "";
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    async show(user) {
      this.isAddOptionalTelephone = false;
      this.telList = [];
      this.telList.push({ tel: "", ext: "" });
      this.form = user;
      if (this.form.optional_telephone) {
        this.fixTelList();
      }
      await this.$store.dispatch("setMainLoading", true);
      await this.getField();
      await this.$store.dispatch("setMainLoading", false);
      this.showModal = true;
    },
    async getField() {
      const data = await this.$axios.get(
        `${this.$baseUrl}/customer/registerform/1`
      );

      if (data.result === 1) {
        this.fields = data.detail.result.fields.filter(
          (el) => el.show_on_edit_profile == 1
        );

        for (const field of data.detail.result.custom_field) {
          let form = this.form.custom_field.find((el) => el.id == field.id);

          if (form) {
            let value = form.user_answer.map((el) => el.answer);
            if (value.length == 0)
              value = field.field_choices
                .filter((el) => el.isDefault == 1)
                .map((el) => el.name);
            if (field.field_type_id == 2) {
              field.user_answer = value;
            } else {
              field.user_answer = value.toString();
            }
          }
        }

        this.dynamicField = data.detail.result.custom_field.filter(
          (el) => el.show_on_edit_profile == 1
        );
      }
    },
    fixTelList() {
      this.telList = this.form.optional_telephone.split(",").map((tel) => {
        const [number, ext] = tel.split("-");
        return { tel: number, ext: ext || "" };
      });
    },
    hide() {
      this.showModal = false;
    },
    handleSuccess() {
      this.$emit("success");
      this.hide();
    },
    async save() {
      await this.$refs.registerFields.save();
    },
    addOptinalTel() {
      if (
        !this.limitOptionalTel ||
        this.telList.length < this.limitOptionalTel
      ) {
        this.telList.push({ tel: "", ext: "" });
      }
    },
    AddOptionalTelephone() {
      this.isAddOptionalTelephone = true;
    },
    closeOptionalPhone() {
      this.telList = [];
      this.$v.$reset();
      this.fixTelList();
      this.isAddOptionalTelephone = false;
    },
    saveOptionalPhone() {
      this.$v.telList.$touch();
      if (!this.$v.$error) {
        this.telList = this.telList.filter((item) => item.tel.trim() !== "");

        if (this.telList.length === 0) {
          this.telList.push({ tel: "", ext: "" });
        }
        this.form.optional_telephone = this.telList
          .map((item) => (item.ext ? `${item.tel}-${item.ext}` : item.tel))
          .join(",");

        this.isAddOptionalTelephone = false;
      }
    },
    deleteTel(index) {
      if (index == 0) {
        this.telList[0].tel = "";
        this.telList[0].ext = "";
        return;
      }
      this.telList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .modal-register {
  .btn-link {
    border-color: transparent !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    padding: 0;
  }
  .register-box {
    background: #f7f7f7;
    border: 1px solid #d8dbe0;
  }
  .nav-tabs {
    .nav-item {
      margin-bottom: 0px;
    }
    .nav-link {
      border: 1px solid white !important;
      border-bottom: 3px solid white !important;
      font-weight: 600;
      background: white !important;
      color: #333 !important;
    }
    .nav-link.active {
      border: 1px solid var(--secondary-color) !important;
      border-bottom: 3px solid var(--primary-color) !important;
      color: var(--primary-color) !important;
    }
  }
  .title {
    color: #333 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    width: 100%;
    position: relative;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
}

@media (max-width: 768px) {
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

.icon-delete {
  width: 20px;
  height: 20px;
}
</style>
